//<Link className="col-sm-3 backround Link" to={"/collections/" + key}>

import { Link } from "react-router-dom"


export const Footer = () => {

    return(
        <footer className="footer">

            <ul className="footerList">
                <li className="footerListItemZero">Mellori</li>

                <Link className="footerListItem" to="/about">TOS</Link>
                <Link className="footerListItem" to="/about">About</Link>



            </ul>

        </footer>

    )
}