import { Link } from "react-router-dom";

export const LinkRoute = (props) => {

    /* 
    : to /
    + to #
    , to .
    */

    const arrayOfSubassets = props.data.split(":")
    const arrayOfSubassets2 = props.data.split("+")

    let linkOfRoute
    let name

    if(props.data.includes("+")){

        linkOfRoute = <p className="text-no-margin"><Link className="Link" to={"/collections/" + arrayOfSubassets2[0].replace("/", ":")}>{arrayOfSubassets2[0].replace(":", "/")}</Link></p>
        name = <p className="text-no-margin">{arrayOfSubassets2[1]}</p>

    } else if (props.data.includes(":")) {
        name = <p className="text-no-margin">{arrayOfSubassets[arrayOfSubassets.length - 1]}</p>


    } else {
        linkOfRoute = <p className="text-no-margin"><Link className="Link" to={"/collections/" + props.data.replace("/", ":")}>{props.data}</Link></p>
        name = <p className="text-no-margin">{props.data}</p>
    }




    return (
        <div>    
            {linkOfRoute}
            {name}
        </div>    
        )
}