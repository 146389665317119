import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Api } from "../data/Api";
import { IPFS_PROVIDER } from "../data/IPFS_PROVIDER";

export const Collections = () => {
    const [response, setResponse] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorLoadingImage, setErrorLoadingImage] = useState({});

    useEffect(() => {
        setIsLoading(true);
        axios({
            method: "GET",
            url: Api + "/get_listed_collections"
        }).then((res) => {
            setResponse(res.data);
            setIsLoading(false);
        }).catch(error => {
            console.error(error.response);
            setIsLoading(false);
        });
    }, []);

    const handleImageError = (key) => {
        setErrorLoadingImage({ ...errorLoadingImage, [key]: true });
    };

    const renderAsset = (key, image) => {
        if (image === "no_image") {
            return <img src="/placeholder.png" alt="Placeholder" className="image-single-nft" />;
        }

        const fileExtension = image.split('.').pop().toLowerCase();
        const isVideo = ['mp4', 'webm', 'ogg'].includes(fileExtension);
        const is3DModel = ['glb', 'gltf'].includes(fileExtension);

        if (isVideo) {
            return (
                <video className="image-single-nft" controls>
                    <source src={IPFS_PROVIDER + image} type={`video/${fileExtension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        }

        if (is3DModel) {
            // For 3D models, you might want to use a specialized 3D viewer library
            // For now, we'll just display a placeholder image
            return <img src="/3d-model-placeholder.png" alt="3D Model" className="image-single-nft" />;
        }

        return (
            <img
                src={IPFS_PROVIDER + image}
                alt={`${key} collection`}
                className="image-single-nft"
                onError={() => handleImageError(key)}
            />
        );
    };

    return (
        <div className="container">
            <h1>Collections</h1>
            {isLoading ? (
                <video width="100" height="100" autoPlay loop>
                    <source src="/512animation.mp4" type="video/mp4" />
                </video>
            ) : (
                Object.keys(response).length > 0 ? (
                    <div>
                        <p>Explore a world of Ravencoin digital assets: Discover and anonymously acquire unique Collections on our on-chain vending machine.</p>
                        <hr className="line"></hr>
                        <div className="row">
                            {Object.keys(response).map(key => (
                                <Link key={key} className="col-sm-3 background Link" to={`/collections/${key}`}>
                                    <div className="Wallet_Asset">
                                        {renderAsset(key, response[key]["image"])}
                                        <p className="text-no-margin">{key}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                ) : (
                    <p>Nothing to see here</p>
                )
            )}
        </div>
    );
};
