
export const About = () => {


    return(
        <div className="container">
            <h1>About</h1>
            <hr className="line"></hr>
            <p>Mellori is the 'vending machine' for assets on Ravencoin. The site your are looking at right now is the second iteration of mellori, the initial version was built in late 2021.</p>

            <h2>Contributors</h2>
            <hr className="line"></hr>

            <div className="row">
                <div className="col-sm-4 ">
                    <div className="contributors">
                        <img width="100%" height="auto" src="https://images.unsplash.com/profile-1655550034854-83645d6bee39image?dpr=2&auto=format&fit=crop&w=150&h=150&q=60&crop=faces&bg=fff" />
                        <p className="contributorName">Concordance</p>
                        <a target="_blank" className="greenLink" href="https://twitter.com/0xconcordance">Twitter</a>
                        <p className="greenLink">concordance#9543</p>

                    </div>
                </div>

                <div className="col-sm-4 ">
                    <div className="contributors">
                        <img width="100%" height="auto" src="https://pbs.twimg.com/profile_images/1590687768702726144/W-wqAq-W_400x400.jpg" />
                        <p className="contributorName">Cerberus</p>
                        <a target="_blank" className="greenLink" href="https://twitter.com/cerberus_rvn">Twitter</a>
                        <p className="greenLink">cerberus.#0429</p>

                    </div>
                </div>

                <div className="col-sm-4 ">
                    <div className="contributors">
                        <img width="100%" height="auto" src="https://pbs.twimg.com/profile_images/1494405417148956672/xTne-8T2_400x400.jpg" />
                        <p className="contributorName">Jwong</p>
                        <a target="_blank" className="greenLink" href="https://twitter.com/jwong_27">Twitter</a>
                        <p className="greenLink">jwong#7591</p>

                    </div>
                </div>
            </div>

        </div>
    )
}