import axios from "axios";
import { useEffect, useState } from "react";
import { SellNavbar } from "../Components/SellNavbar"
import { useToken } from "../Components/useToken"
import { Api } from "../data/Api";


export const SellProfile = (props) => {
    const { token, userID } = useToken();
    const [response, setResponse] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        axios({
            method: "POST",
            url: Api + "/get_user_profile_data",
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => {
            setResponse(res.data)
            setIsLoading(false)
        }).catch(error => {
            console.log(error.response)
            setIsLoading(false)
        })
    }, [])

    return (
        <div className="">
            <SellNavbar removeToken={props.removeToken} />

            <div className="container">
                <h1>Your Profile</h1>
                <p>Here you can check on your profile data. To change the data please contact us on discord.</p>
                <hr className="line"></hr>

                {isLoading ? (
                <video width="100" height="100"autoPlay loop>
                    <source src="/512animation.mp4" />
                </video>
            ) : (

                    <div>
                        <p>Username: <span className="greenTxt">{response["username"]}</span></p>
                        <p>Email: <span className="greenTxt">{response["email"]}</span></p>
                        {response["private"] ? (
                            <p>Private Profile: <span className="greenTxt">True</span></p>
                        ) : (
                            <p>Private Profile: <span className="greenTxt">False</span></p>
                        )}

                        <p>Receiving Address: <span className="greenTxt">{response["receivingAddress"]}</span></p>

                    </div>

                )}

            </div>
        </div>
    )
}