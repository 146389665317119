import axios from "axios"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Api } from "../data/Api"

export const StatsHistory = () => {

    const [response, setResponse] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [doneLoading, setDoneLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        axios({
            method: "GET",
            url: Api + "/get_marketplace_transactions",
        }).then(res => {
            console.log(res.data)
            setResponse(res.data)
            setIsLoading(false)
            setDoneLoading(true)
        }).catch(error => {
            console.log(error.response)
            setIsLoading(false)
        })
    }, [])

    let newDate = new Intl.DateTimeFormat("de-DE", {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })

    return (
        <div className="container">
            <h1>Stats History</h1>
            <p>The entire transaction history of Mellori.</p>
            <hr className="line"></hr>

            {doneLoading == false ? <p>Loading...</p> : (
                <div>
                        <table>
                            <tr>
                                <th>Timestamp</th>
                                <th>Asset</th>
                                <th>Price</th>
                                <th>Buyer</th>
                            </tr>
                            {Object.keys(response).sort().reverse().map(key => {
                                    return (
                                    <tr className="item">
                                        <td>{newDate.format(response[key]["unix"] * 1000)}</td>
                                        <td>{response[key]["amount"]} {response[key]["asset"].replaceAll(":", "/").replaceAll("+", "#")}</td>
                                        <td>{response[key]["price"]} {response[key]["denomination"].replaceAll(":", "/")}</td>
                                        <td>{response[key]["buyer_address"].substring(0, 12)}...</td>
                                    </tr>
                                )})}
                        </table>

                </div>
            )}

        </div>
    )
}