import axios from "axios"
import { useState } from "react"
import { useNavigate   } from 'react-router-dom';

import { SellNavbar } from "../Components/SellNavbar"
import { Api } from "../data/Api";

export const Register = (props) => {

    const [registerForm, setRegisterForm] = useState({
        email: "",
        password: "",
        receivingAddress: "",
        betacode: ""
    })
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)
    const [isRegistered, setIsRegistered] = useState(false)
    const [isError, setIsError] = useState(false)

    const createAccount = (event) => {
        setIsLoading(true)
        setIsError(false)

        axios({
            method: "POST",
            url: Api + "/create_account",
            data: {
                email: registerForm.email,
                password: registerForm.password,
                receivingAddress: registerForm.receivingAddress,
                betacode: registerForm.betacode
            }
        }).then((response) => {
            console.log(response)
            setIsRegistered(true)
            setIsLoading(false)

        }).catch(error => {
            if (error.response) {
                console.log(error.response)
                console.log(error.response.status)
                if(error.response.status == 500){
                    setIsError(true)
                }
                setIsError(true)
                setIsLoading(false)

                console.log(error.response.headers)
            }
        })

        setRegisterForm(({
            email: "",
            password: "",
            receivingAddress: "",
            betacode: ""
        }))

        event.preventDefault()
    }

        
    const handleChange = (event) => {
        const {value, name} = event.target
        setRegisterForm(prevNote => ({
            ...prevNote, [name]: value
        }))
    }

    return (
        <div>
            <SellNavbar removeToken={props.removeToken} />
            <div className="container logreg">

            <h1>Create Account</h1>


            {isLoading ? (
                                                <video width="100" height="100"autoPlay loop>
                                                <source src="/512animation.mp4" />
                                            </video>
            
            )
            
                :
                
                <form>
                    <input 
                    className="inputFieldField"
                    onChange={handleChange}
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={registerForm.email}
                    />

                    <input 
                    
                    className="inputFieldField"
                    onChange={handleChange}
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={registerForm.password}
                    />

                    <input 
                    className="inputFieldField"
                    onChange={handleChange}
                    type="text"
                    name="receivingAddress"
                    placeholder="Receivingaddress"
                    value={registerForm.receivingAddress}
                    />

                    <input 
                    className="inputFieldField"
                    onChange={handleChange}
                    type="text"
                    name="betacode"
                    placeholder="Beta Code"
                    value={registerForm.betacode}
                    />


                    <button onClick={createAccount} className="greenButton">Create Account</button>

                </form>
            } 

            {isRegistered ? <p>Account created! Please Verify Email.</p> : null}
            {isError ? <p>Error creating account!</p> : null}
            </div>

        </div>
    )

}