import { useState } from "react"
import { UniqueAsset } from "./UniqueAsset"
import { FungibleAssets } from "./FungibleAssets"

export const DefaultAsset = (props) => {

    // 2 different UIs for when the user owns more than >1 token 
    if(props.data["amount"] == 1) {
        return (
            <div className="container assetPage">
                <UniqueAsset data={props.data} balance={props.balance}/>
            </div>
        )
    } else {
        return (
            <div className="container assetPage">
                <FungibleAssets data={props.data} balance={props.balance}/>
            </div>
        )

    }


}