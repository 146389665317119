import { Routes, Route, Link } from "react-router-dom";
import {Nav, Navbar, NavLink} from 'react-bootstrap';


export const NavigationBar = () => {

    return(


        <Navbar collapseOnSelect expand="sm" variant="dark" className="navbar container-fluid" >
            <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="#navbarScroll" />
            <NavLink eventKey="0" as={Link} to="/"><img src="/Mellori-03.svg" className="logoNavBarIcon" /><span className="logoTXT">Mellori</span></NavLink>

            <Navbar.Collapse id="navbarScroll">
                <Nav className="container-fluid">
                    <NavLink className="navItem" eventKey="1" as={Link} to="/collections">Collections</NavLink>
                    <NavLink className="navItem" eventKey="2" as={Link} to="/stats">Stats</NavLink>
                    <NavLink className="navItem" eventKey="3" as={Link} to="/orders">Orders</NavLink>
                    <NavLink className="navItem" eventKey="4" as={Link} to="/sell/dashboard">Sell</NavLink>

                </Nav>
            
            </Navbar.Collapse>
        </Navbar>

    )
    }