import axios from "axios"
import { useEffect, useState } from "react"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useToken } from "../../Components/useToken"
import { Api } from "../../data/Api";
import { IPFS_PROVIDER } from "../../data/IPFS_PROVIDER";
import { LinkRouteHeader } from "../../Components/LinkRouteHeader";
import { SendModal } from "../../Components/Modals/SendModal";
import { AssetInfoBox } from "../../Components/AssetInfoBox";
import { PurchaseGuide } from "../../Components/PurchaseGuide";
import { useStoreOrders } from "../../Components/Data/useStoreOrders";

export const FungibleAssets = (props) => {

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const listToken = () => {
        setShow(true)
    }

    const [delistTarget, setDelistTarget] = useState("")
    const [delistShow, setDelistShow] = useState(false)

    const delistClick = (target) => {
        setDelistTarget(target)
        setDelistShow(true)
    }

    const handleDClose = () => setDelistShow(false);
    const handleDShow = () => setDelistShow(true);

    const [response, setResponse] = useState({})    
    const { token, userID, removeToken } = useToken();
    const [submitted, setSubmitted] = useState(false)
    const { setOrder, orders } = useStoreOrders();

    const [avail, setAvail] = useState(0)

    useEffect(() => {
        // we get all the listings and display them to the user
        axios({
            method: "POST",
            url: Api + "/get_all_listings_for_asset",
            data: {
                "full_root": props.data["name"]
            },
            
        }).then((res) => {
            setResponse(res.data)
        }).catch(error => {
            console.log(error.response)
        })

        axios({
            method: "POST",
            url: Api + "/get_available_asset",
            data: {
                "full_root": props.data["name"]
            },
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => {
            setAvail(res.data)
        }).catch(error => {
            console.log(error.response)
        })

    }, [])

    const [listingWorked, setListingWorked] = useState(false)

    const onListingModalSubmit = (e) => {
        e.preventDefault()

        const price = e.target.price.value
        let currency = e.target.currency.value
        currency = currency.toUpperCase()
        const amount = e.target.amount.value

        if (currency == "") {
            currency = "RVN"
        }

        // since it has been submited we need to do a post request to the server to list the token
        axios({
            method: "POST",
            url: Api + "/list_asset",
            headers: {
                Authorization: "Bearer " + token
            },
            data: {
                "full_root": props.data["name"],
                "price": price,
                "currency": currency,
                "amount": amount
            }

        }).then((res) => {
            console.log(res)
            setListingWorked(true)
        }).catch(error => {
            console.log(error.response)
        })
        
        setSubmitted(true)
    }

    const [delistWorked, setDelistWorked] = useState(false)


    const onDelistingSubmit = (e) => {
        e.preventDefault()
        axios({
            method: "POST",
            url: Api + "/delist_token",
            headers: {
                Authorization: "Bearer " + token
            },
            data: {
                "full_root": props.data["name"],
                "order_id": delistTarget
            }
        }).then(response => {
            console.log(response.data)
            // delisting worked 
            setDelistWorked(true)
        }).catch(error => {
            console.log(error.response)
        })
    }

    // BUYING FUNCTIONALITY
    const [buyTarget, setBuyTarget] = useState("")
    const [buyShow, setBuyShow] = useState(false)
    const buyClick = (target) => {
        setBuyTarget(target)
        setBuyShow(true)
    }

    const handleBShow = () => setBuyShow(true)
    const handleBClose = () => setBuyShow(false)

    const [generate, setGenerate] = useState(false)
    const [purchaseResponse, setPurchaseResponse] = useState({})
    const [purchaseID, setPurchaseID] = useState("")
    const [purchaseShow, setPurchaseShow] = useState(false)
    const handlePClose = () => setPurchaseShow(false);
    const handlePShow = () => setPurchaseShow(true);

    const onBuySubmit = (e) => {
        e.preventDefault()
        
        setGenerate(true)
        const receivingAddress = e.target.elements.address.value


        axios({
            method: "POST",
            url: Api + "/generate_order",
            data: {
                "full_root": props.data["name"],
                "receiving_address": receivingAddress,
                "order_id": buyTarget
            }

        }).then(res => {
            setPurchaseID(res.data)
            setOrder(res.data)
            // we get the data right away 
            axios({
                method: "GET",
                url: Api + "/get_buy_order_data/" + res.data,
            }).then((response) => {
                setPurchaseResponse(response.data)
                setGenerate(false)
                setPurchaseShow(true)
                setBuyShow(false)
    
            }).catch(error => {
                console.log(error.response)
            })

        }).catch(error => {{
            console.log(error.response)
            setGenerate(false)

        }})


    }

    const [checking, setChecking] = useState(false)

    const [success, setSuccess] = useState(false)
    const [paymentResponse, setPaymentResponse] = useState({})

    const checkOrder = () => {
        console.log("CEHCKING ORDER")
        setChecking(true)
        axios({
            method: "GET",
            url: Api + "/check_payment/" + purchaseID,
        }).then((res) => {
            setChecking(false)
            setPaymentResponse(res.data)
            setSuccess(true)
        }).catch(error => {
            console.log(error.response)
            setChecking(false)
        })

    }

    const [showSendingModal, setShowSendingModal] = useState(false)
    const handleSendModalOpen = () => setShowSendingModal(true)
    const handleSendModalClose = () => setShowSendingModal(false)

    const [imageError, setImageError] = useState(false)
    const imageErrorWhenLoading = () => {
        console.log("THis is a test")
        setImageError(true)
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-6">
                    {imageError == false ? (
                    <img src={IPFS_PROVIDER + props.data["ipfs_hash"]} className="assetImage" onError={imageErrorWhenLoading}/>
                    ) : (
                    <iframe src={IPFS_PROVIDER + props.data["ipfs_hash"]} className="assetImage" width="15rem" height="700rem" loading="lazy" />
                 )}

                </div>

                <div className="col-sm-6">
                        <LinkRouteHeader data={props.data["name"]} />
                        <AssetInfoBox data={props.data}/>

                        <p>You own <span className="greenTxt">{props.balance[props.data["name"]]}</span> of these NFTs.</p>
                        <p>Available Balance: {avail}</p>

                        <button onClick={listToken} className="greenButton">List</button>
                        <button className="greenButton" onClick={handleSendModalOpen}>Send</button>
                        <br></br>
                        <br></br>
                    {response != null ? (
                        <div>
                            <table>
                                <tr>
                                    <th>ID</th>
                                    <th>Amount</th>
                                    <th>Price</th>
                                </tr>
                                {Object.keys(response).map(key => {
                                
                                    if(response[key]["seller"] == userID) {
                                        return(
                                            <tr>
                                            <td>{key}</td>
                                            <td>{response[key]["amount"]}</td>
                                            <td>{response[key]["price"]} {response[key]["denomination"]}</td>
                                            <button className="GreenTableButton" onClick={() => delistClick(key)}>Delist</button>
                                        </tr>
                                        )
                                    } else{
                                        return(  <tr>
                                            <td>{key}</td>
                                            <td>{response[key]["amount"]}</td>
                                            <td>{response[key]["price"]} {response[key]["denomination"]}</td>
                                            <button className="GreenTableButton" onClick={() => buyClick(key)}>Buy</button>
                                        </tr>)    
                                    }
                                    
                            })}

                            </table>
                            </div>
                            ) : (
                                <p>No Listings</p>
                            )}

                </div>
            </div>
            
            <SendModal  show={showSendingModal} handleClose={handleSendModalClose} fungible={true} root={props.data["name"]}/>

            <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>List Token</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                    {listingWorked ? (
                        <div className="modal-body">
                            <p>Listing created, refresh the page plz.</p>
                        </div>
                    ) : (
                        <div className="modal-body"> 
                    
                        <form onSubmit={onListingModalSubmit}>
                        <label>Price:</label>
                        <input type="number" name="price" placeholder="0" required/>

                        <label>Currency:</label>
                        <input type="text" name="currency" placeholder="Default: RVN" />
                        
                        <label>Amount:</label>
                        <input type="number" name="amount" placeholder="list" />
                        <small className="form-text text-muted">Max. {avail}</small>

                        <input type="submit" className='greenButton' value="List Asset for Sale"/>
                        
                        </form>
             
                    </div>

                    )}

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="" onClick={handleClose}>Close</Button>
                </Modal.Footer>
                </Modal>

            <Modal show={delistShow} onHide={handleDClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delist Token</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                    {delistWorked ? (
                        <div className="modal-body">
                            <p>Listing Deleted, plz refresh page.</p>
                        </div>
                    ) : (
                        <div className="modal-body"> 
                    
                        <form onSubmit={onDelistingSubmit}>
                        <p>You sure you want to delist?</p>

                        <input type="submit" className='greenButton' value="Delist Asset"/>
                        
                        </form>
             
                    </div>

                    )}


                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="" onClick={handleDClose}>Close</Button>
                </Modal.Footer>
                </Modal>

            {buyShow ? (
                <Modal show={buyShow} onHide={handleBClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Buy Token</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <div className="modal-body"> 
                        

                        <img src={IPFS_PROVIDER + props.data["ipfs_hash"]} className="assetImage" />
                                    
                        <p className="orderPData">I want to purchase <span className="orderData">{response[buyTarget]["amount"]} {props.data["name"]}</span> for <span className="orderData">{response[buyTarget]["price"]} {response[buyTarget]["denomination"]}</span>.</p>


                        {generate ? (
                                <p>Generating Order</p>
                            ) : (
                                <form onSubmit={onBuySubmit}>

                                <label>Receiving Address:</label>
                                <input type="text" name="address" placeholder="Enter your address" required/>
    
    
                                <input type="submit" className='greenButton' value="Generate Order"/>
                                
                            </form>
    
                            )}

                
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="" onClick={handleBClose}>Close</Button>
            </Modal.Footer>
            </Modal>

            ) : (
                <div></div>
            )}
        

        {purchaseShow ? (
            <Modal show={purchaseShow}>
            <Modal.Header>
                <Modal.Title>Buy Token</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {success ? (
                    <div>
                    <p>🥳 The Asset is on it's way!! 🥳</p>
                    <p>{paymentResponse["result"]}</p>

                    </div>
                ) : (
                    <div className="modal-body"> 
                    
                    <PurchaseGuide />
                    
                    <img src={IPFS_PROVIDER +  props.data["ipfs_hash"]} className="assetImage" />
                        
                    <p className="orderPData">I want to purchase <span className="orderData">{response[buyTarget]["amount"]} {props.data["name"]}</span> for <span className="orderData">{response[buyTarget]["price"]} {response[buyTarget]["denomination"]}</span>.</p>                    
                    <span>Send Payment here:</span>
                    <p className="important">{purchaseResponse["payment_address"]}</p>
                    <div className="qrImage">
                    <img className="qrCode" src={"https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=%20" + purchaseResponse["payment_address"]} alt="Payment Address"></img>
                    </div>
                    {checking ? (
                        <p>Checking Order...</p>
                    ) : (
                        <input type="submit" className='greenButton' value="Check Payment" onClick={checkOrder}/>
                    )}

                </div>

                )}

            </Modal.Body>

            <Modal.Footer>
                <Button variant="" onClick={handlePClose}>Close</Button>
        </Modal.Footer>
        </Modal>

        ) : (
            <></>
        )}



        </div>


)}