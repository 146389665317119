import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";


export const SellNavbar = (props) => {

    const [accountData, setAccountData] = useState({
        name: "Anonymous",
        profile_pic: "https://i.imgur.com/XyqQZ9x.png",
    })


    const deleteLocalStorage = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userID');

    }

    return (
        <div>
        
        {localStorage.getItem('token') != null ? 
        <nav className="navbar navbar-expand-lg navbar-dark container">
            <div className="" id="navbarNav">
                <ul className="navbar-nav">

                <Link className="navItem nav-item nav-link" to="/sell/dashboard">Dashboard</Link>
                <Link className="navItem nav-item nav-link" to="/sell/profile">Profile</Link>
                <Link className="navItem nav-item nav-link" to="/sell/history">History</Link>

                <li className="navItem nav-item nav-link" onClick={props.removeToken}>Logout</li>

                </ul>
            </div>
        </nav>
        : 
        <nav className="navbar navbar-expand-lg navbar-dark container">
            <div className="" id="navbarNav">
                <ul className="navbar-nav">
    
                <Link className="navItem nav-item nav-link" to="/sell/dashboard">Dashboard</Link>
                <Link className="navItem nav-item nav-link" to="/sell/login">Login</Link>
                <Link className="navItem nav-item nav-link" to="/sell/create-account">Register</Link>

                </ul>
            </div>
        </nav>
        }
        
        </div>
    )

    
    }