import React from 'react';
import { Routes, Route, Link } from "react-router-dom";

import {NavigationBar} from './Components/Navbar'
import { useToken } from './Components/useToken';

import {Home} from './Pages/Home'
import {SellDashboard} from './Pages/SellDashboard'

import { Login } from "./Pages/Login"
import {Register} from './Pages/Register'
import { Asset } from './Pages/Asset';

import {Collections} from './Pages/Collections'
import { SellProfile } from './Pages/SellProfile';
import { SellHistory } from './Pages/SellHistory';
import { SingleCollection } from './Pages/SingleCollection';
import { Stats } from './Pages/Stats';
import { StatsHistory } from './Pages/StatsHistory';
import { useStoreOrders } from './Components/Data/useStoreOrders'
import { Orders } from './Pages/Orders';
import { Footer } from './Components/Footer';
import { About } from './Pages/About';


function App() {

  const { token, removeToken, setToken, userID, setUserID } = useToken();
  return (
    <div>
      
    <div className="main-page">

      <NavigationBar />

      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/sell/dashboard" element={<SellDashboard removeToken={removeToken} token={token} userID={userID} />} />
        <Route path='/sell/profile' element={<SellProfile removeToken={removeToken} token={token} userID={userID}/>}/>
        <Route path='/sell/history' element={<SellHistory removeToken={removeToken} token={token} userID={userID}/>}/>


        <Route path="/sell/login" element={<Login setToken={setToken} setUserID={setUserID} removeToken={removeToken}/>} />
        <Route path="/sell/create-account" element={<Register removeToken={removeToken} />} />

        <Route path="/collections" element={<Collections />} />

        <Route path="/stats" element={<Stats />} />
        <Route path="/stats/history" element={<StatsHistory />} />

        <Route path='/asset/:id' element={<Asset />} />
        
        <Route path='/collections/:route' element={<SingleCollection />}/>

        <Route path='/orders' element={<Orders />}/>


        <Route path='/about' element={<About />}/>


        </Routes>


        
        </div>
    <br></br>
    <Footer />

    </div>
  );
}

export default App;
