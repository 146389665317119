import axios from "axios"
import { useEffect, useState } from "react"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Api } from "../../../data/Api";
import { IPFS_PROVIDER } from "../../../data/IPFS_PROVIDER";
import { LinkRouteHeader } from "../../../Components/LinkRouteHeader";
import { AssetInfoBox } from "../../../Components/AssetInfoBox";
import { PurchaseGuide } from "../../../Components/PurchaseGuide";
import { useStoreOrders } from "../../../Components/Data/useStoreOrders";

export const NotOwnedUnqiue = (props) => {
    const [response, setResponse] = useState({})

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [purchaseShow, setPurchaseShow] = useState(false)
    const handlePClose = () => setPurchaseShow(false);
    const handlePShow = () => setPurchaseShow(true);

    const [generate, setGenerate] = useState(false)
    const [purchaseResponse, setPurchaseResponse] = useState({})
    const [purchaseID, setPurchaseID] = useState("")

    const { setOrder, orders } = useStoreOrders();


    useEffect(() => {
        // here we check if the token is listed
        axios({
            method: "POST",
            url: Api + "/get_listing_data_for_unique_asset",
            data: {
                "full_root": props.data["name"]
            }
        }).then((res) => {
            setResponse(res.data)
        
        }).catch(error => {
            console.log(error.response)
        })
    }, [])

    const onPurchaseSubmit = (e) => {
        // here we generate a order
        // and then hide the modal
        // and open the buy modal
        e.preventDefault()
        setGenerate(true)

        const receivingAddress = e.target.elements.address.value

        axios({
            method: "POST",
            url: Api + "/generate_order",
            data: {
                "full_root": props.data["name"],
                "receiving_address": receivingAddress,
                "order_id": response["order_id"]
            }

        }).then(res => {
            setPurchaseID(res.data)
            setOrder(res.data)
            // we get the data right away 
            axios({
                method: "GET",
                url: Api + "/get_buy_order_data/" + res.data,
            }).then((response) => {
                setPurchaseResponse(response.data)
                setGenerate(false)
                setPurchaseShow(true)
                setShow(false)
                
            }).catch(error => {
                console.log(error.response)
            })

        }).catch(error => {{
            console.log(error.response)
            setGenerate(false)

        }})
    }


    const [checking, setChecking] = useState(false)

    const [success, setSuccess] = useState(false)
    const [paymentResponse, setPaymentResponse] = useState({})

    const checkOrder = () => {
        setChecking(true)
        axios({
            method: "GET",
            url: Api + "/check_payment/" + purchaseID,
        }).then((res) => {
            setChecking(false)
            setPaymentResponse(res.data)
            setSuccess(true)
        }).catch(error => {
            console.log(error.response)
            setChecking(false)
        })

    }
    
    const [imageError, setImageError] = useState(false)
    const imageErrorWhenLoading = () => {
        console.log("THis is a test")
        setImageError(true)
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-6">
                {imageError == false ? (
                    <img src={IPFS_PROVIDER + props.data["ipfs_hash"]} className="assetImage" onError={imageErrorWhenLoading}/>
                    ) : (
                    <iframe src={IPFS_PROVIDER + props.data["ipfs_hash"]} className="assetImage" width="15rem" height="700rem" loading="lazy"/>
                 )}
                </div>
                <div className="col-sm-6">
                    <LinkRouteHeader data={props.data["name"]} />
                    <AssetInfoBox data={props.data}/>

                        {response["price"] != undefined ? (
                            <div>
                                <h3>{response["price"]} {response["denomination"]}</h3>
                                <button className="greenButton" onClick={handleShow}>Buy</button>
                            </div>
                        ) : (
                            <p>Not listed</p>
                        )}

                </div>
                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Buy Token</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body"> 
                            <img src={IPFS_PROVIDER + props.data["ipfs_hash"]} className="assetImage" />
                                
                            <p className="orderPData">I want to purchase <span className="orderData">{props.data["name"]}</span> for <span className="orderData">{response["price"]} {response["denomination"]}</span>.</p>
                            
                            {generate ? (
                                <p>Generating Order</p>
                            ) : (
                                <form onSubmit={onPurchaseSubmit}>
                                <label>Receiving Address:</label>
                                <input type="text" name="address" placeholder="Enter your address" required/>
    
                                <input type="submit" className='greenButton' value="Generate Order"/>
                                
                                </form>

                            )}
                
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="" onClick={handleClose}>Close</Button>
                </Modal.Footer>
                </Modal>

                <Modal show={purchaseShow}>
                    <Modal.Header>
                        <Modal.Title>Buy Token</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {success ? (
                            <div>
                            <p>🥳 The Asset is on it's way!! 🥳</p>
                            <p>{paymentResponse["result"]}</p>

                            </div>
                        ) : (
                            <div className="modal-body"> 

                            <PurchaseGuide />

                            <img src={IPFS_PROVIDER + props.data["ipfs_hash"]} className="assetImage" />
                                
                            <p className="orderPData">I want to purchase <span className="orderData">{props.data["name"]}</span> for <span className="orderData">{response["price"]} {response["denomination"]}</span>.</p>
                            <span>Send Payment here:</span>
                            <p className="important">{purchaseResponse["payment_address"]}</p>
                            <div className="qrImage">
                             <img className="qrCode" src={"https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=%20" + purchaseResponse["payment_address"]} alt="Payment Address"></img>
                            </div>
                            {checking ? (
                                <p>Checking Order</p>
                            ) : (
                                <input type="submit" className='greenButton' value="Check Payment" onClick={checkOrder}/>
                            )}

                        </div>

                        )}

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="" onClick={handlePClose}>Close</Button>
                </Modal.Footer>
                </Modal>


        </div>
    )
}