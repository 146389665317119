import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { LinkRoute } from "../Components/LinkRoute";
import { useToken } from "../Components/useToken";
import { CollectionAsset } from "../Components/CollectionAsset";
import { Api } from "../data/Api";
import { IPFS_PROVIDER } from "../data/IPFS_PROVIDER";
import { CollectionStats } from "../Components/CollectionStats";

export const SingleCollection = (props) => {

    let { route } = useParams();
    const { token, userID } = useToken();

    const [response, setResponse] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        axios({
            method: "GET",
            url: Api + "/get_every_listed_token_by_route/" + route,
        }).then((res) => {
            setResponse(res.data)
            setIsLoading(false)

            console.log(res.data)

        }).catch(error => {
            console.log(error.response)
            setIsLoading(false)
        })
    }, [])


    const compArr = []

    Object.keys(response).map((keys) => {
        compArr.push(<CollectionAsset data={keys.replace(":", "/").replace("+", "#")} full_data={response[keys]} />)
    })

    return (
        <div className="container">
            <h1>{route}</h1>
            <CollectionStats collection={route} />
            <hr className="line"></hr>
            {response == "Error" ? <p>Something went wrong.</p> : 
                <div>
                    <div className="row">
                        {compArr}
                    </div>
            </div>}


        </div>
    )
}