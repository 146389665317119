import { Link } from "react-router-dom"

export const Home = () => {

    return (
        <div className="homepage">

            <div className="container">

            <div className="header">
                <img src="/Mellori-03.svg" className="headerImage"/>
                <h1>Mellori Marketplace</h1>
                <p>Mellori is the fastest way to trade assets on Ravencoin.</p>
                <br></br>
                <br></br>
                <Link className="greenButton linkSpan" to="/collections"><span className="linkSpan">Explore</span></Link>
            </div>

            </div>


        </div>
    )
}