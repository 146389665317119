import axios from "axios"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Api } from "../data/Api"
import { IPFS_PROVIDER } from "../data/IPFS_PROVIDER"
import { LinkRoute } from "./LinkRoute"

export const WalletAsset = (props) => {
    
    const [response, setResponse] = useState({})

    const formatLink = (param) => {
        let new_root = param.replaceAll("#", "+") 
        let new_new_root = new_root.replaceAll("/", ":")
        return new_new_root.replaceAll("!", "")
    }
    
    useEffect(() => {
        axios({
            method: "POST",
            url: Api + "/get_asset_metadata",
            data: {
                full_root: props.data
            }
        }).then((response) => {
            setResponse(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
                console.log(error.response.status)
            }
        })
    }, [])

    

    return (
        <Link to={"/asset/" + formatLink(props.data)} className="col-sm-3 backround Link">
            <div className="Wallet_Asset">

                {response["has_ipfs"] != "0" ? (
                    <img src={IPFS_PROVIDER + response["ipfs_hash"]} className="image-single-nft" />
                    ) : (
                    <img src="/placeholder.png" className="image-single-nft" />
                    )}
                                        


                <LinkRoute data={formatLink(props.data)} response={response} />
                <br></br>
            </div>
        </Link>
    )

}