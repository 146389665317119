import { IPFS_PROVIDER } from "../data/IPFS_PROVIDER"
import { useEffect, useState } from "react"

export const AssetInfoBox = (props) => {



    const ipfs_hash = props.data["ipfs_hash"]
    let asset_name = props.data["name"]

    console.log(asset_name)

    const [ravencoinAssetExplorerLink, setRavencoinAssetExplorerLink] = useState("")
    
    useEffect(() => {
        if(asset_name == undefined) {
            setRavencoinAssetExplorerLink("")
        } else {
            setRavencoinAssetExplorerLink("https://ravencoin.asset-explorer.net/a/" + asset_name.replaceAll("/", "%2F").replaceAll("#", "%23").replaceAll(".", "%2C"))
        }
    }, [asset_name])
    
    
    console.log(props.data)

    return ( 
        <>      
        <br></br>
        <div className="assetInfoBox">
            
            
            <h5 className="greenTxt">Asset Data</h5>
            <span>Full Root: {props.data["name"]}</span><br></br>
            {props.data["reissuable"] == 1 ? (
                <div>
                <span>Reissuable: Yes</span><br></br>
                <span>Current Supply: {props.data["amount"]}</span><br></br>
                </div>
            ): (
                <div>
                <span>Reissuable: No</span><br></br>
                <span>Supply: {props.data["amount"]}</span><br></br>
                </div>
            )}

            {props.data["has_ipfs"] == 1 ? (
                <div>
                <span>IPFS Hash: <a target="_blank" className="greenTxt no-decoration" href={IPFS_PROVIDER + props.data["ipfs_hash"]}>{props.data["ipfs_hash"].substring(0, 12)}...</a></span><br></br>
                </div>
            ): (
                <>
                </>
            )}

            <span>Asset Explorer: <a target="_blank" className="greenTxt no-decoration" href={ravencoinAssetExplorerLink}>{props.data["name"]}</a></span>


        </div>
        <br></br>
        </>   
    )

}