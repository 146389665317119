import axios from "axios"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Api } from "../data/Api"

export const Stats = () => {

    const [response, setResponse] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [doneLoading, setDoneLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        axios({
            method: "GET",
            url: Api + "/get_marketplace_stats",
        }).then(res => {
            console.log(res.data)
            setResponse(res.data)
            setIsLoading(false)
            setDoneLoading(true)
        }).catch(error => {
            console.log(error.response)
            setIsLoading(false)
        })
    }, [])

    let newDate = new Intl.DateTimeFormat("de-DE", {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })

    return (
        <div className="container">
            <h1>Marketplace Stats</h1>
            <p>Check out what is going on with Mellori. Updates every hour.</p>
            <br></br>
            <Link className="greenButton text-no-margin link" to="/stats/history">Full Transaction History</Link>
            <br></br>
            <br></br>

            <hr className="line"></hr>

            {doneLoading == false ? <p>Loading...</p> : (
                <div>
                        <div>
                        <p>Last Update: {newDate.format(response["time"] * 1000)}</p>

                        <h4>Listing Data</h4>
                        <hr className="line"></hr>
                        <span>Total Number of Listings: {response["listing_data"]["listing_counter"]}</span><br></br>
                        <span>Total Number of Listings (RVN): {response["listing_data"]["rvn_listings"]}</span><br></br>
                        <span>Total Number of Listings (Asset): {response["listing_data"]["asset_listings"]}</span><br></br>
    
                        <h4>Order Data</h4>
                        <hr className="line"></hr>
                        <span>Total Number of Trades: {response["order_data"]["num_of_trades"]}</span><br></br>
                        <span>Total Number of Trades (RVN): {response["order_data"]["rvn_denominated_trades"]}</span><br></br>
                        <span>Total Number of Trades (Asset): {response["order_data"]["asset_denominated_trades"]}</span><br></br>
    
                        <h4>User Data</h4>
                        <hr className="line"></hr>
                        <span>Total Number of Sellers: {response["user_data"]["number_of_users"]}</span><br></br>
                        
                        <h4>Volume Data</h4>
                        <hr className="line"></hr>
                        <br></br>
                        <table>
                            <tr>
                                <th>Asset</th>
                                <th>Volume</th>
                            </tr>
                            {Object.keys(response["volume_data"]).map(key => {
                                    return (
                                    <tr className="item">
                                        <td>{key}</td>
                                        <td>{response["volume_data"][key]}</td>
                                    </tr>
                                )})}
                        </table>
                        </div>
                    

                </div>
            )}
        <br></br>
        </div>
    )
}