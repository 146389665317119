import { useState } from "react";

export const useToken = () => {

    const getToken = () => {
        const userToken = localStorage.getItem('token');
        return userToken && userToken    
    }

    const getUserId = () => {
        const userId = localStorage.getItem('userID');
        return userId && userId
    }

    const [token, setToken] = useState(getToken());
    const [userID, setUserID] = useState(getUserId());

    const saveToken = (userToken) => {
        localStorage.setItem('token', userToken);
        setToken(userToken);
    }

    const removeToken = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userID');
        setToken(null);
        setUserID(null);
    }

    const saveUserID = (userID) => {
        localStorage.setItem('userID', userID);

    }

    return {
        setToken: saveToken,
        token,
        userID,
        removeToken,
        setUserID: saveUserID
    }

}