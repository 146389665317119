import { NotOwnedFungible } from "./NotOwned/NotOwnedFungible"
import { NotOwnedUnqiue } from "./NotOwned/NotOwnedUnique"

export const NotOwned = (props) => {

    if(props.data["amount"] == 1) {
        return (
            <div className="container assetPage">
                <NotOwnedUnqiue data={props.data} />
            </div>
        )
    } else {
        return (
            <div className="container assetPage">
                <NotOwnedFungible data={props.data} />
            </div>
        )

    }


}