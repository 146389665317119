import axios from "axios";
import { useEffect, useState } from "react";
import { Api } from "../data/Api";
import { useToken } from "./useToken"

export const DepositAddress = () => {

    const { token, userID, removeToken } = useToken();
    const [response, setResponse] = useState({})

    useEffect(() => {
        axios({
            method: "POST",
            url: Api + "/get_user_data/" + userID,
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((response) => {
            setResponse(response.data)
        }).catch(error => {
            if (error.response) {
                setResponse("Error")
                console.log(error.response)
                console.log(error.response.status)    
                
                if (error.response.status === 401) {
                    removeToken()
                }

            }
        })
    }, [])

    return (
        <div className="depositAddress ">
            <h2>Deposit Address</h2>
            <p className="depositAddressText">This is the address where you can deposit your assets. It never changes. Deposits can take a few minutes. Please be patient. <br></br>DO NOT SEND RVN TO THIS ADDRESS!</p>
            <p className="depositAddressField">{response["depositAddress"]}</p>
            
        </div>
        )
}