import axios from "axios"
import { useEffect, useState } from "react"
import { SellNavbar } from "../Components/SellNavbar"
import { WalletAsset } from "../Components/WalletAsset"

import { DepositAddress } from "../Components/DepositAddress"

import { useToken } from "../Components/useToken"
import { Api } from "../data/Api"

export const SellDashboard = (props) => {

    const [response, setResponse] = useState({})
    const { token, userID } = useToken();

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        axios({
            method: "GET",
            url: Api + "/get_user_balance/" + userID,
        }).then((response) => {
            setResponse(response.data)
            setIsLoading(false)
        }).catch(error => {
            if (error.response) {
                setResponse("Error")
                console.log(error.response)
                console.log(error.response.status)    
                setIsLoading(false)
            }
        })
    }, [])

    const compArr = []

    Object.keys(response).map((keys) => {
        compArr.push(<WalletAsset data={keys}/>)
    })
    

    
    return (
        <div className="">
            <SellNavbar removeToken={props.removeToken} />
            <div className="container">
                <h1>Sell Dashboard </h1>
                <p>Manage your deposits and listed tokens.</p>

                {token ? 

                    <div>
                        {isLoading ? 
                        (
                            <div>
                                <video width="100" height="100"autoPlay loop>
                                    <source src="/512animation.mp4" />
                                </video>
                            </div>
                        )
                        : 
                        (
                            <div>
                                <DepositAddress />
                                <h2>Your Assets</h2>
                                <p>This is the confirmed balance of your Deposit Address.</p>
                                <hr className="line"></hr>

                                {response == "Error" ? <p>Something went wrong.</p> : 
                                <div>
                                    <div className="row">
                                        {compArr}
                                    </div>
                                </div>}
                            </div>
                            
                        )}





                    </div>
                
                : 
                
                <p>Please Login or create an account.</p>}
            
            </div>
        </div>
    )
}