import { Link } from "react-router-dom";

export const LinkRouteHeader = (props) => {

    if(props.data == undefined){
        return (
            <div>No data</div>
        )
    }

    const arrayOfSubassets = props.data.split("/")
    const arrayOfSubassets2 = props.data.split("#")

    let linkOfRoute
    let name

    if(props.data.includes("#")){

        linkOfRoute = <p className="text-no-margin"><Link className="Link" to={"/collections/" + arrayOfSubassets2[0].replace("/", ":")}>{arrayOfSubassets2[0].replace(":", "/")}</Link></p>
        name = <h1>{arrayOfSubassets2[1]}</h1>

    } else if (props.data.includes("/")) {
        name = <h1>{arrayOfSubassets[arrayOfSubassets.length - 1]}</h1>


    } else {
        linkOfRoute = <p className="text-no-margin"><Link className="Link" to={"/collections/" + props.data.replace("/", ":")}>{props.data}</Link></p>
        name = <h1>{props.data}</h1>
    }




    return (
        <div>    
            {linkOfRoute}
            {name}
        </div>    
        )

}