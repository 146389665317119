// retrive the orders from the local browser storage

import axios from "axios";
import { useEffect, useState } from "react";
import { useStoreOrders } from "../Components/Data/useStoreOrders";
import { Api } from "../data/Api";

export const Orders = () => {

    const { setOrder, orders } = useStoreOrders();
    console.log(orders)


    // data we need for the listings 
    // api calls, pass array of orderIDs

    const [orderData, setOrderData] = useState({})
    const [doneLoading, setDoneLoading] = useState(false)

    useEffect(() => {
        setDoneLoading(false)
        axios({
            method: "POST",
            url: Api + "/get_order_infomation",
            data: {
                "formatJSON": orders
            }
        }).then(res => {
            console.log(res.data)
            
            setOrderData(res.data)
            setDoneLoading(true)
        }).catch(error => {
            console.log(error)
        })
        
    }, [])

    const [checkingOrder, setCheckingOrder] = useState({})
    const [orderFilled, setOrderFilled ] = useState({})

    // orderid: false, true

    const checkOrder = (orderid) => {
        console.log("checking order bro")

        // set the loading thing
        
        console.log(checkingOrder[orderid])

        // constructing json document

        setCheckingOrder({...checkingOrder, orderid:true})

        axios({
            method: "GET",
            url: Api + "/check_payment/" + orderid,
        }).then((res) => {
            alert("Asset Sent.")
        }).catch(error => {
            alert("Payment not received" + error.response["data"])
        })

    }


    console.log(orderData)

    let newDate = new Intl.DateTimeFormat("de-DE", {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })

    if (doneLoading == false) {
        return(
            <div className="container">
            <h1>Orders</h1>
            <p>All the orders that are stored in the local Browser Storage.</p>
            <hr className="line"></hr>
            <p>Loading</p>
            </div>
        )
    } else {
    return(
        <div className="container">
            <h1>Orders</h1>
            <p>All the orders that are stored in the local Browser Storage.</p>
            <hr className="line"></hr>


            <table>
                            <tr>
                                <th>Asset</th>
                                <th>Price</th>
                                <th>Payment</th>
                                <th>Expires</th>
                                <th>Fill</th>

                            </tr>
                            
                            {Object.keys(orderData).map(key => {
                                    // check if the order still exists 
                                    // need to loop here

                                    if (orderData[key]["exists"] == false) {
                                        return (
                                            <tr className="item">
                                                <td>{orderData[key]["root"]}</td>
                                                <td>n/a</td>
                                                <td>{orderData[key]["payment_address"]}</td>
                                                <td>n/a</td>
                                                {checkingOrder[key] == true ? (
                                                    <p>Checking Order</p>
                                                ): (
                                                    <button className="GreenTableButton" id={key} onClick={() => checkOrder(key)}>Refund</button>
                                                )}
                                            </tr>
                                        )
                                        }
                                        else {
                                        return(
                                            <tr className="item">
                                                <td>{orderData[key]["root"]}</td>
                                                <td>{orderData[key]["price"] + " " + orderData[key]["denomination"]}</td>
                                                <td>{orderData[key]["payment_address"]}</td>

                                                {orderData[key]["expires"] >= Date.now() ? (
                                                    <td>Order Expired</td>
                                                ) : (
                                                    <td>{newDate.format(orderData[key]["expires"] * 1000)}</td>
                                                )}

                                                {orderData[key]["expires"] >= Date.now() ? (
                                                    <td><button className="GreenTableButton" disabled id={key} onClick={() => checkOrder(key)}>Expired</button></td>

                                                ) : (
                                                    <td><button className="GreenTableButton" id={key} onClick={() => checkOrder(key)}>Check</button></td>

                                                )}



                                            </tr>
                                    )}})}
                </table>
                

        </div>

    )
}   
}
