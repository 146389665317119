import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Api } from "../data/Api";
import { IPFS_PROVIDER } from "../data/IPFS_PROVIDER";
import { LinkRoute } from "./LinkRoute";

export const CollectionAsset = (props) => {
    const [response, setResponse] = useState({});
    const [errorLoadingImage, setErrorLoadingImage] = useState(false);

    useEffect(() => {
        axios({
            method: "POST",
            url: Api + "/get_asset_metadata",
            data: { full_root: props.data }
        }).then((res) => {
            setResponse(res.data);
            // Initially, assume no error until proven otherwise
            setErrorLoadingImage(false); 
        }).catch((error) => {
            console.error("Error fetching asset metadata:", error.response || error);
        });
    }, [props.data]);

    const formatLink = (param) => {
        return param.replace("#", "+").replace("/", ":").replace("!", "");
    };

    const handleImageLoaded = () => {
        // If the image loads successfully, ensure error state is not set
        setErrorLoadingImage(false);
    };

    const handleImageError = () => {
        // Directly set error state when an error occurs, without a delay
        setErrorLoadingImage(true);
    };

    const renderAsset = () => {
        const imageUrl = response["has_ipfs"] !== "0" ? IPFS_PROVIDER + response["ipfs_hash"] : "/placeholder.png";

        return errorLoadingImage ? (
            <iframe src={imageUrl} title="Asset" className="image-single-nft" loading="lazy"></iframe>
        ) : (
            <img src={imageUrl} alt="Asset" className="image-single-nft" onError={handleImageError} onLoad={handleImageLoaded} />
        );
    };

    return (
        <Link to={"/asset/" + formatLink(props.data)} className="col-sm-3 background Link">
            <div className="Wallet_Asset">
                {renderAsset()}
                <LinkRoute data={formatLink(props.data)} response={response} />
                {props.data.toString().includes("#") ? (
                    <p>{props.full_data[Object.keys(props.full_data).toString()]["price"]} {props.full_data[Object.keys(props.full_data).toString()]["denomination"]}</p>
                ) : (
                    <p>Multiple Listings</p>
                )}
            </div>
        </Link>
    );
};
