import axios from "axios";
import { useEffect, useState } from "react";
import { Api } from "../data/Api";

export const CollectionStats = (props) => {

    const collection = props.collection

    const [response, setResponse] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        axios({
            method: "GET",
            url: Api + "/get_collection_describtion/" + collection,
        }).then((res) => {
            setResponse(res.data)
            setIsLoading(false)

            console.log(res.data)

        }).catch(error => {
            console.log(error.response)
            setIsLoading(false)
        })
    }, [])

    if(isLoading == true) {
        return(<div>Loading...</div>)
    } else {
        return(
            <div className="depositAddress">
                <span className="greenTxt">Royalties:</span><span> {response["royalties"]/100}%</span><br></br>

                <span className="greenTxt">Description:</span>
                <p>{response["describtion"]}</p>
            </div>  
        )
}}