import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import { IPFS_PROVIDER } from '../../data/IPFS_PROVIDER';
import axios from 'axios';
import { Api } from '../../data/Api';
import { useToken } from "../../Components/useToken"

export const SendModal = (props) => {
    // here we send stuff
    // there are 2 different ways to send stuff
    // unique assets and fungible assets
    
    // 

    const [transferingStatus, setTransferingStatus] = useState(false)
    const [transferingSucess, setTransferingSucess] = useState(false)
    const { token, userID, removeToken } = useToken();

    const [transferResult, setTransferResult] = useState({})

    const onTransferRequest = (e) => {
        e.preventDefault()
        setTransferingStatus(true)
        const receivingAddress = e.target.address.value
        console.log(receivingAddress)
        
        let amount = 0;

        if(props.fungible) {
            amount = e.target.amount.value
        } else {
            amount = 1
        }

        console.log(props.root)
        console.log(receivingAddress)
        console.log(amount)


        axios({
            method: "POST",
            url: Api + "/send_asset",
            data: {
                "full_root": props.root,
                "recipient_address": receivingAddress,
                "amount": amount
            },
            headers: {
                Authorization: "Bearer " + token
            }

        }).then(res => {
            setTransferResult(res.data)
            setTransferingSucess(true)
            setTransferingStatus(false)
            console.log(res.data)
        }).catch(error => {
            console.log(error.response)
            setTransferingStatus(false)

        })

    }

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Send Asset</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {transferingSucess ? (
                    <div className="modal">
                    <p>🥳 The Asset is on it's way!! 🥳</p>

                    </div>
                ) : (

                    <div className="modal-body"> 
                        <p>Sending: {props.root}</p>
                    <form onSubmit={onTransferRequest}>

                        <label>Receiving Address:</label>
                        <input type="text" name="address" placeholder="Enter an address..." required />

                        {props.fungible ? (
                            <div>
                                <label>Amount:</label>
                                <input type="number" name="amount" placeholder="Enter an amount..." required/>
                            </div>
                        ) : (
                            <></>
                        )}

                    {transferingStatus ? (
                        <p>Transfering...</p>
                    ) : (
                        <input type="submit" className='greenButton' value="Send Asset" />
                    )}

                    </form>


                </div>

                )}

            </Modal.Body>

            <Modal.Footer>
                <Button variant="" onClick={props.handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    )

}
