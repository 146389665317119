import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isConstructorDeclaration } from 'typescript';

import { useToken } from '../Components/useToken';
import { Api } from '../data/Api';
import { NotOwned } from './IndividualAssets/NotOwned';

import { DefaultAsset } from './IndividualAssets/OwnedAsset';

export const Asset = () => {

    let { id } = useParams();
    const [response, setResponse] = useState({})
    const { token, userID } = useToken();

    const [balance, setBalance] = useState({})


    const reformatRoot = (root) => {
        let new_root = root.replace("+", "#")
        return new_root.replace(":", "/")
    }

    const removeExlasmashion = (root) => {
        return root.replace("!", "")
    }
    
    useEffect (() => {
        axios({
            method: "POST",
            url: Api + "/get_asset_metadata",
            data: {
                full_root: reformatRoot(id)
            }}).then((res) => {
            setResponse(res.data);
        }).catch(error => {
            console.log(reformatRoot(id))
            console.log(error.response)
        })}, [])

    // We need 2 seperate return statements - for when the user owns the NFT and when they don't      

    useEffect(() => {
        if (userID == null) {
            console.log("Token is null")
        }
        else {    
            axios({
                method: "GET",
                url: Api + "/get_user_balance/" + userID,
            }).then((response) => {
                setBalance(response.data)
            }).catch(error => {
                if (error.response) {
                    console.log(error.response)
                    console.log(error.response.status)    
                }
            })    
        }
    }, [])

    if (balance[response["name"]]) {
        // the user owns the NFT
        return <DefaultAsset data={response} balance={balance}/>

    } else {
        return <NotOwned data={response}/>
        
    }

    }
