import axios from "axios"
import { useEffect, useState } from "react"
import { useToken } from "../../Components/useToken"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Api } from "../../data/Api";
import { IPFS_PROVIDER } from "../../data/IPFS_PROVIDER";
import { SendModal } from "../../Components/Modals/SendModal";
import { LinkRouteHeader } from "../../Components/LinkRouteHeader";
import { AssetInfoBox } from "../../Components/AssetInfoBox";


export const UniqueAsset = (props) => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { token, userID, removeToken } = useToken();
    const [submitted, setSubmitted] = useState(false)
    const [listingWorked, setListingWorked] = useState(false)
    const [listingError, setListingError] = useState(false)

    const [response, setResponse] = useState({})

    const listToken = () => {
        setShow(true)
    }

    const onListingModalSubmit = (e) => {
        e.preventDefault()

    

        const price = e.target.price.value
        let currency = e.target.currency.value
        currency = currency.toUpperCase()

        if (currency == "") {
            currency = "RVN"
        }

        // since it has been submited we need to do a post request to the server to list the token
        axios({
            method: "POST",
            url: Api + "/list_asset",
            headers: {
                Authorization: "Bearer " + token
            },
            data: {
                "full_root": props.data["name"],
                "price": price,
                "currency": currency,
                "amount": 1   
            }

        }).then((res) => {
            console.log(res)

            // listing worked
            setListingWorked(true)
        }).catch(error => {
            console.log(error.response)

            // listing did not work, result is error
            setListingError(true)
        })
        
        setSubmitted(true)
    }


    const [delistTarget, setDelistTarget] = useState("")
    const [delistShow, setDelistShow] = useState(false)

    const [delistResponse, setDelistResponse] = useState({})

    const delistClick = (target) => {
        setDelistTarget(target)
        setDelistShow(true)
    }

    const handleDClose = () => setDelistShow(false);
    const handleDShow = () => setDelistShow(true);
    
    const [delistWorked, setDelistWorked] = useState(false)

    const onDelistingSubmit = (e) => {
        e.preventDefault()
        axios({
            method: "POST",
            url: Api + "/delist_token",
            headers: {
                Authorization: "Bearer " + token
            },
            data: {
                "full_root": props.data["name"],
                "order_id": response["order_id"]
            }
        }).then(response => {
            setDelistResponse(response.data)
            setDelistWorked(true)
        }).catch(error => {
            console.log("DELLLISTOOOR 12")
            console.log(error.response)
        })
    }

    
    
    useEffect(() => {
        // here we check if the token is listed
        axios({
            method: "POST",
            url: Api + "/get_listing_data_for_unique_asset",
            data: {
                "full_root": props.data["name"]
            }
        }).then((res) => {
            setResponse(res.data)
        
        }).catch(error => {
            console.log(error.response)
        })
    }, [delistResponse])


    const [showSendingModal, setShowSendingModal] = useState(false)
    const handleSendModalOpen = () => setShowSendingModal(true)
    const handleSendModalClose = () => setShowSendingModal(false)

    const [imageError, setImageError] = useState(false)
    const imageErrorWhenLoading = () => {
        console.log("THis is a test")
        setImageError(true)
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-6">
                {imageError == false ? (
                    <img src={IPFS_PROVIDER + props.data["ipfs_hash"]} className="assetImage" onError={imageErrorWhenLoading}/>
                    ) : (
                    <iframe src={IPFS_PROVIDER + props.data["ipfs_hash"]} className="assetImage" width="15rem" height="700rem" loading="lazy"/>
                 )}
                </div>
                <div className="col-sm-6">
                    <LinkRouteHeader data={props.data["name"]} />
                    <AssetInfoBox data={props.data}/>

                    {response["seller"] == userID ? (
                        <div>
                           <h3>{response["price"]} {response["denomination"]}</h3>
                           <button onClick={handleDShow} className="greenButton">Delist</button>

                        </div>
                    ) : (
                        <div>
                            <button onClick={listToken} className="greenButton">List</button>
                            <button className="greenButton" onClick={handleSendModalOpen}>Send</button>
                        </div>

                    )}
                </div>

                <SendModal show={showSendingModal} handleClose={handleSendModalClose} fungible={false} root={props.data["name"]}/>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>List Token</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        { listingWorked ? (
                            <div className="modal-body">
                                <p>Asset Listed, plz refresh page.</p>
                            </div>
                        ) : (
                            <div className="modal-body"> 
                    
                            <form onSubmit={onListingModalSubmit}>
                            <label>Price:</label>
                            <input type="number" name="price" placeholder="0" required/>
    
                            <label>Currency:</label>
                            <input type="text" name="currency" placeholder="Default: RVN" />
    
                            <input type="submit" className='greenButton' value="List Asset for Sale"/>
                            
                            </form>
                 
                            </div>
    
                        ) }

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="" onClick={handleClose}>Close</Button>
                </Modal.Footer>
                </Modal>
                
                <Modal show={delistShow} onHide={handleDClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delist Token</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        {delistWorked ? (
                            <div className="modal-body">
                                <p>Asset Delisted, plz refresh page.</p>
                            </div>
                        ) : (
                            <div className="modal-body"> 
                    
                            <form onSubmit={onDelistingSubmit}>
                            <p>You sure you want to delist?</p>
    
                            <input type="submit" className='greenButton' value="Delist Asset"/>
                            
                            </form>
                 
                        </div>
    
                        )}

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="" onClick={handleDClose}>Close</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </div>

    )
}