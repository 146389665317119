export const PurchaseGuide = () => {
    return (
        <div className="purchaseGuide">
            <p className="purchaseinfo">
                ANON PURCHASE GUIDE:<br />
                1. After sending the RVN, do not close or refresh this page!<br />
                2. Your transaction is processed immediately and takes a few minutes to confirm on chain.<br />
                3. Use "Check Payment" every few minutes to confirm and fill the order.<br />
                4. Check your orders - mellori.tech/orders
            </p>
        </div>
    );
}
