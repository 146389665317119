import axios from "axios"
import { useState } from "react"
import { SellNavbar } from "../Components/SellNavbar"
import { useNavigate   } from 'react-router-dom';
import { Api } from "../data/Api";

export const Login = (props) => { 

    const [loginForm, setLoginForm] = useState({
        email: "",
        password: ""
    })
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [emailVerified, setEmailVerified] = useState(true)

    const logMeIn = (event) =>{
        setIsLoading(true)
        setIsError(false)
        setEmailVerified(true)

        axios({
            method: "POST",
            url: Api + "/login_account",
            data: {
                email: loginForm.email,
                password: loginForm.password
            }
        }).then((response) => {
            console.log(response.data)
            if (response.data == "Email not verified"){
                setEmailVerified(false)
            } else {
                props.setToken(response.data.access_token)
                props.setUserID(response.data.user_id)    
                navigate('/sell/dashboard')
            }
            setIsLoading(false)

        }).catch(error => {
            if (error.response) {
                console.log(error.response)
                console.log(error.response.status)
                console.log(error.response.headers)
                setIsLoading(false)
                setIsError(true)
            }
        })

        setLoginForm(({
            email: "",
            password: ""
        }))

        event.preventDefault()
    }

    const handleChange = (event) => {
        const {value, name} = event.target
        setLoginForm(prevNote => ({
            ...prevNote, [name]: value
        }))
    }

    return (
        
        <div>
            <SellNavbar removeToken={props.removeToken} />
            <div className="container logreg">
            <h1>Login</h1>
            
            {isLoading ? (
                                                <video width="100" height="100"autoPlay loop>
                                                <source src="/512animation.mp4" />
                                            </video>
            
            ) : 
            <form>
            <input 
            className="inputFieldField"
            onChange={handleChange}
            type="email"
            name="email"
            placeholder="Email"
            value={loginForm.email}
            />

            <input 
            className="inputFieldField"
            onChange={handleChange}
            type="password"
            name="password"
            placeholder="Password"
            value={loginForm.password}
            />

            <button onClick={logMeIn} className="greenButton">Login</button>

        </form>
        } 

        {isError ? <p>Error logging in.</p> : null}
        {!emailVerified ? <p>Email not verified.</p> : null}
        </div>
        </div>
    )

}