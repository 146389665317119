import { SellNavbar } from "../Components/SellNavbar"
import { useToken } from "../Components/useToken"
import { Api } from "../data/Api";
import axios from "axios";
import { useEffect, useState } from "react";


export const SellHistory = (props) => {
    const { token, userID } = useToken();
    const [response, setResponse] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        axios({
            method: "GET",
            url: Api + "/get_transaction_history_for_seller" ,
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((response) => {
            setResponse(response.data)
            setIsLoading(false)
        }).catch(error => {
            console.log(error.response)
            setIsLoading(false)
        })

    }, [])

    let newDate = new Intl.DateTimeFormat("de-DE", {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })

    return (
        <div className="">
            <SellNavbar removeToken={props.removeToken} />
            <div className="container">
                <h1>History</h1>
                <p>Monitor all your sales.</p>
                <hr className="line"></hr>

                {isLoading ? (
                    <video width="100" height="100"autoPlay loop>
                        <source src="/512animation.mp4" />
                    </video>
                
                ) : (
                    <div className="container">
                        <table className="sortable">
                            <tr>
                                <th>Time</th>
                                <th>Asset</th>
                                <th>Price</th>
                                <th>Buyer</th>
                            </tr>

                            {Object.keys(response).sort().reverse().map(key => {
                                return (
                                <tr className="item">
                                    <td>{newDate.format(response[key]["unix"] * 1000)}</td>
                                    <td>{response[key]["amount"]} {response[key]["asset"].replace("+", "#").replace(":", "/")}</td>
                                    <td>{response[key]["price"] + " " + response[key]["denomination"]}</td>
                                    <td>{response[key]["buyer_address"].substring(0, 12)}</td>
                                </tr>
                            )})}
                        </table>
                    </div>

                    )}

            </div>

        </div>
    )
}