import { useState } from "react";

export const useStoreOrders = () => {

    // create a json that gets converted to a string  

    const getOrdersAsJson = () => {
        const ordersString = localStorage.getItem("orders");
        // turn into json
        const ordersJson = JSON.parse(ordersString);
        return ordersJson
    }

    const [orders, setOrders] = useState(getOrdersAsJson())

    const addOrder = (orderID) => {
        // get the order as a json 
        const order = getOrdersAsJson()
        console.log("want to add this order id" + orderID)

        // check if the order is null 
        if (order == null) {
            // the order is null which means we need to initialize it
            console.log("order is null")

            const orderToAdd = [orderID]
            localStorage.setItem("orders", JSON.stringify(orderToAdd))

        } else {
            // order is already filled with json stuff so we just append it onto it.
            console.log("order is not null")

            // get the thing as json
            const orders = getOrdersAsJson()
            orders.push(orderID)
            localStorage.setItem("orders", JSON.stringify(deleteDuplicateOrders(orders)))

        }
    }

    const deleteDuplicateOrders = (array) => {
        const uniqueArray = array.filter((item, index, self) => self.indexOf(item) === index);
        console.log(uniqueArray);  // ["apple", "banana", "cherry", "fig"]

        return uniqueArray

    }


    return {
        orders,
        setOrder: addOrder
    }
    
}

// when user first enters 

// following functions
// "saveorder"
// "remove order"